<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" width="370px" :show-close="false" @close="close()">
    <div class="popup">
      <el-form>
        <el-form-item>
          <div class="label red-text">{{title}}单价：{{unitPrice}}元/小时</div>
          <el-input v-model="inputValue" type="number"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="label">价格合计：</div>
          <el-input disabled :value="inputValue * unitPrice"></el-input>
        </el-form-item>
      </el-form>
      <div class="label">支付方式：</div>
      <TaskCoinPay></TaskCoinPay>
      <div class="rule">
        <div>1.{{title}}成功后未到期前可以续期，时间自动累加；</div>
				<div>2.{{title}}只针对本任务有效；</div>
				<div>3.任务途中暂停，{{title}}时间不会暂停；</div>
				<div>4.提前下架或{{title}}未到期任务提前完成，剩余{{title}}时间不会保留也不会折算成任务币返还；</div>
      </div>
      <div class="btn-group">
        <div class="btn-cancel btn-border" @click="close()">取消</div>
        <div class="btn-confirm btn-fill-theme" @click="confirm()">确认支付</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import TaskCoinPay from '@/components/TaskCoinPay'
export default {
  components:{
    TaskCoinPay
  },
  data() {
    return {
      dialogVisible: false,
      inputValue: '',
      act: '',
      taskId: ''
    }
  },
  computed: {
    title() {
      if(this.act == 5) return '置顶'
      if(this.act == 6) return '推荐'
      return ''
    },
    unitPrice() {
      const memberBenefit = this.$store.state.memberBenefit
      if(this.act == 5) return memberBenefit.topPrice
      if(this.act == 6) return memberBenefit.recommendPrice
    }
  },
  methods: {
    open(info) {
      this.act = info.act
      this.taskId = info.taskId
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
      this.inputValue = ''
    },
    confirm() {
      if(!this.inputValue || Number(this.inputValue) <= 0) return this.$tips({ type: 'warning', message: `请输入${this.title}时长` })
      const formData = {
        'taskId': this.taskId,
        'type': this.act,
        'extParam': {
          'time': Number(this.inputValue),
          'price': String(this.unitPrice),
          'totalPrice': String(this.inputValue * this.unitPrice),
        }
      }
      console.log('formData', formData)
      this.$emit('confirm', formData)
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input.is-disabled .el-input__inner {
  color: #666666 !important;
}
.label {
  color: #65666F;
  font-size: 14px;
  line-height: 40px;
}
.rule {
  font-size: 14px;
  color: #99A3A3;
  margin-top: 24px;
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  .btn-cancel, .btn-confirm {
    padding: 10px 24px;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 12px;
    cursor: pointer;
  }
  
}
</style>