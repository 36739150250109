import request from '@/utils/request'

/**
  * 获取应用配置
  */
export function appInfoApi(data) {
  return request({
    url: 'app/appInfo',
    method: 'POST',
    data
  })
}

/**
  * 获取oss配置
  */
export function uploadConfigApi(data = {}) {
  return request({
    url: 'app/upConf',
    method: 'POST',
    data
  })
}

/**
  * 获取会员权益
  */
export function memberBenefitApi(data = {}) {
  return request({
    url: 'app/memberBenefit',
    method: 'POST',
    data
  })
}

export function connectApi(data = {}) {
  return request({
    url: 'sseEmitter/connect/'+data.userId,
    method: 'POST'
  })
}