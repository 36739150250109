<template>
  <div class="pay pay-active">
    <img src="@/assets/images/icon-money.png">
    <span class="name">任务币支付</span>
    <span>| 余额：{{userInfo.taskCyBalance || 0}}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style lang="scss" scoped>
.pay {
  display: flex;
  align-items: center;
  height: 56px;
  border-radius: 4px;
  font-size: 12px;
  color: #65666F;
  padding: 0 18px;
  cursor: pointer;
  .name {
    font-size: 14px;
    font-weight: bold;
    margin-right: 4px;
  }
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.pay-active {
  border: 1px solid var(--theme-color);
  background: #FFF2ED;
}
</style>