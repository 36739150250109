<template>
  <div class="empty">
    <img v-if="img" :src="img" :style="imgStyle">
    <div :style="tipsStyle">{{tips}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    img: {
      type: String,
      default: require('@/assets/images/nothing-config.png')
    },
    tips: {
      type: String,
      default: '暂无数据'
    },
    imgStyle: {
      type: Object,
      default: () => ({})
    },
    tipsStyle: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #B0B3BF;
  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
}
</style>