import request from '@/utils/request'

/**
  * im登录
  */
export function getImTokenApi(data = {}) {
  return request({
    url: 'message/getUserToken',
    method: 'POST',
    data
  })
}