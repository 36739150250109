<template>
  <div :class="['home', auction.home ? 'mt24':'']">
    <!-- 公告 -->
    <div class="notice" v-if="auction.home">
      <div class="notice-wrap">
        <img class="icon-notice" src="@/assets/images/icon-notice.png">
        <div>您参与的首页竞价正在进行中，您当前排名第{{auction.home.rank}}名，距离结束时间</div>
        <CountDown v-if="auction.home.endCountDown" :time="auction.home.endCountDown * 1000">
          <template v-slot="scoped">
            <div class="notice-countdown">
              <div>{{scoped.hour}}</div>
              <span>:</span>
              <div>{{scoped.minute}}</div>
              <span>:</span>
              <div>{{scoped.second}}</div>
            </div>
          </template>
        </CountDown>
      </div>
    </div>
    <div class="panel">
      <PanelTitle title="任务推广"/>
      <div class="promote-task" v-if="spreadSections && spreadSections.length>0">
        <div v-for="(item, index) in spreadSections[0].actions" :key="index" @click="spreadAction(item, index)">
          <img :src="getPromoteIcon(item)">
          <div>{{item.text}}</div>
        </div>
        <!-- <div>
          <img src="@/assets/images/icon-syjj.png">
          <div>首页竞价</div>
        </div>
        <div>
          <img src="@/assets/images/icon-ttyy.png">
          <div>头条预约</div>
        </div>
        <div>
          <img src="@/assets/images/icon-mstj.png">
          <div>秒杀推荐</div>
        </div>
        <div>
          <img src="@/assets/images/icon-dtjj.png">
          <div>大厅竞价</div>
        </div>
        <div>
          <img src="@/assets/images/icon-rmss.png">
          <div>热门搜索</div>
        </div>
        <div>
          <img src="@/assets/images/icon-tctj.png">
          <div>弹窗推荐</div>
        </div>
        <div>
          <img src="@/assets/images/icon-dszd.png">
          <div>定时置顶</div>
        </div>
        <div>
          <img src="@/assets/images/icon-dstj.png">
          <div>定时推荐</div>
        </div>
        <div>
          <img src="@/assets/images/icon-mszq.png">
          <div>秒审专区</div>
        </div>
        <div>
          <img src="@/assets/images/icon-xgsx.png">
          <div>修改刷新</div>
        </div>
        <div>
          <img src="@/assets/images/icon-zdsx.png">
          <div>自动刷新</div>
        </div> -->
      </div>
    </div>
    <!-- 最近上架任务 -->
    <div class="content">
      <div class="content-left">
        <div class="tabs">
          <span class="active">最近上架的任务</span>
        </div>
        <!-- 任务列表 -->
        <TaskList v-if="taskList && taskList.length > 0" :list="taskList" @update="getTaskList"></TaskList>
        <div v-else class="panel empty">
          <Empty :img="require('@/assets/images/nothing-empty.png')" tips="暂无上架的任务"/>
        </div>
        <div class="more-task" @click="$router.push('/publishManage/list')">
          <span>查看全部任务</span>
          <img src="@/assets/images/arrow-right.png">
        </div>
      </div>
      <div class="content-right">
        <div class="panel mb12">
          <div class="flex between-center">
            <PanelTitle title="店铺推广"/>
            <div>
              <div class="flex items-center red-text fs14" v-if="manageData && manageData.shopRecCountDown">
                <span>推荐时间：</span>
                <CountDown :time="manageData.shopRecCountDown * 1000"/>
              </div>
              <div class="flex items-center red-text fs14" v-if="manageData && manageData.shopTopCountDown">
                <span>置顶时间：</span>
                <CountDown :time="manageData.shopTopCountDown * 1000"/>
              </div>
            </div>
          </div>
          <div class="promote-shop">
            <div v-for="(item, index) in shopActions" :key="index" @click="shopAction(item)">
              <img v-if="item.icon" :src="item.icon">
              <img v-if="!item.icon && item.act == 1" :src="require('@/assets/images/shop-top.png')">
              <img v-if="!item.icon && item.act == 2" :src="require('@/assets/images/shop-recom.png')">
              <img v-if="!item.icon && item.act == 3" :src="require('@/assets/images/shop-reflesh.png')">
              <div>{{item.text}}</div>
            </div>
          </div>
        </div>
        <div class="panel mb12">
          <PanelTitle title="快捷功能"/>
          <div class="btn-publish btn-fill-theme" @click="$router.push('/publishManage/publishTask')">
            <img src="@/assets/images/icon-fly.png">
            <span>发布任务</span>
          </div>
        </div>
        <Asset></Asset>
      </div>

      <!-- 修改/刷新弹窗 -->
      <RefreshAndModify ref="refreshModifyRef"></RefreshAndModify>
      <!-- 店铺置顶/推荐弹窗 -->
      <ShopRecomTopPopup ref="shopRecomTopRef" @confirm="handleShopAction"></ShopRecomTopPopup>
    </div>
  </div>
</template>

<script>
import TaskList from '@/components/TaskList'
import PanelTitle from '@/components/PanelTitle'
import Asset from '@/components/Asset'
import RefreshAndModify from '@/components/RefreshAndModify'
import ShopRecomTopPopup from './components/ShopRecomTopPopup'
import CountDown from '@/components/CountDown'
import Empty from '@/components/Empty'
import { merchantManageApi, merchantPubListApi, auctionDetailInfoApi } from '@/api/merchant.js'
import { shopManageApi } from '@/api/shop.js'
import { changeRefreshApi } from "@/api/user.js";
export default {
  name: 'Home',
  components: {
    TaskList,
    PanelTitle,
    Asset,
    RefreshAndModify,
    ShopRecomTopPopup,
    CountDown,
    Empty
  },
  data() {
    return {
      manageData: null,
      pubManageStates: [],
      taskList: [],
      spreadSections: [],
      shopActions: [],
      auction: {
        home: null,
        hall: null,
        popup: null
      }
    }
  },
  created() {
    this.getManageData()
    this.getAuctionDetail()
  },
  methods: {
    getManageData() {
      merchantManageApi().then(res => {
        console.log('merchantManage', res)
        this.manageData = res.data
        this.pubManageStates = res.data.pubManageStates
        this.spreadSections = res.data.spreadSections
        this.shopActions = res.data.shopActions
        this.getTaskList() // 已上架
      }).catch(err => {
        this.$tips({type: 'error', message: err})
      })
    },
    getTaskList(index = 1) {
      merchantPubListApi({
        'starPage': 0,
				'statues': this.pubManageStates[index].compositeStatus
      }).then(res => {
        console.log('任务列表', res)
        this.taskList = res.data.pubs || []
      }).catch(err => {
        this.$tips({type: 'error', message: err})
      })
    },
    spreadAction(info, index) {
      console.log(info)
      if(info.url.includes('index=43')) {
        this.$refs.refreshModifyRef.open()
      }
      if(info.url.includes('index=44')) {
        this.$router.push('/auto?type=' + 0)
      }
      if(info.url.includes('index=71')) {
        this.$router.push('/headlineAppoint')
      }
      if(info.url.includes('index=45')) {
        this.$router.push('/seckill')
      }
      if(info.url.includes('index=57')) {
        this.$router.push('/auction?type=0')
      }
      if(info.url.includes('index=64')) {
        this.$router.push('/quickReview')
      }
      if(info.url.includes('index=1016')) {
        const extInfo = info.extInfo
        this.$router.push(`/auction?type=${extInfo.type}&title=${extInfo.title}`)
      }
      if(info.url.includes('index=1015')) {
        this.$router.push('/searchSeat')
      }
      // 其它任务推广排除
      this.$store.commit('SET_SPREAD_EXCLUDE_ID', index)
    },
    shopAction(info) {
      if(info.act == 1 || info.act == 2) {
        this.$refs.shopRecomTopRef.open({act: info.act})
      }
      if(info.act == 3) {
        this.$confirm(this.manageData.shopRefreshTips || '确定执行此操作吗？').then(action => {
          if(action == 'confirm') {
            changeRefreshApi({ needDescription: true })
            .then((res) => {
              const rtimes = res.data.refreshTimes || 0
              if(rtimes >= 3) {
                const userId = localStorage.getItem('USER_ID')
                this.handleShopAction({
                  act: info.act,
                  shopId: userId
                })
              } else {
                this.$confirm('您的刷新次数不足，是否立即购买？（您也可以在【首页】点击【刷新修改】进行购买）', 
                '温馨提示', 
                {confirmButtonText: '去购买'}).then(action1 => {
                  if(action1 == 'confirm') this.$refs.refreshModifyRef.open()
                }).catch(err => {})
              }
            }).catch((err) => {
              this.$tips({ message: err, type: "error" });
            })
          }
        }).catch(err => {})
      }
    },
    handleShopAction(formData) {
      shopManageApi(formData).then(res => {
        this.$tips({type: 'success', message: res.msg})
        this.getManageData()
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({type: 'error', message: err})
      })
    },
    getAuctionDetail() {
      auctionDetailInfoApi({'auctionType': 0}).then(res => {
        console.log('竞拍详情-首页顶部竞拍',res)
        const userId = localStorage.getItem('USER_ID')
        const list = res.data.auctionRecords || []
        if(list.length > 0 && res.data.status == 1) {
          const index = list.findIndex(item => item.userId == userId)
          if(index !== -1) {
            this.auction.home = {
              endCountDown: res.data.endCountDown || 0,
              rank: index + 1
            }
          }
        }
      }).catch(err => {
        this.$tips({ message: err, type: "error" });
      });
    },
    getPromoteIcon(item) {
      const url = item.url
      const extInfo = item.extInfo
      if(url.includes('index=71')) return require('@/assets/images/icon-ttyy.png')
      if(url.includes('index=45')) return require('@/assets/images/icon-mstj.png')
      if(url.includes('index=57')) return require('@/assets/images/icon-syjj.png')
      if(url.includes('index=64')) return require('@/assets/images/icon-mszq.png')
      if(url.includes('index=44')) return require('@/assets/images/icon-zdsx.png')
      if(url.includes('index=43')) return require('@/assets/images/icon-xgsx.png')
      if(url.includes('index=1016')) {
        if(extInfo.type == 1) return require('@/assets/images/icon-dtjj.png')
        if(extInfo.type == 2) return require('@/assets/images/icon-tctj.png')
      }
      if(url.includes('index=1015')) return require('@/assets/images/icon-rmss.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.promote-task {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
  &>div {
    cursor: pointer;
  }
  img {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    margin-bottom: 8px;
  }
}

.content {
  display: flex;
  margin-top: 24px;
}
.content-left {
  flex: 1;
  .tabs {
    display: flex;
    align-items: center;
    height: 54px;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    margin-bottom: 12px;
    padding: 0 24px;
    span {
      margin-right: 16px;
      cursor: pointer;
    }
    .active {
      position: relative;
      color: var(--theme-color);
      &::after {
        content: '';
        position: absolute;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
        width: 48px;
        height: 2px;
        background: var(--theme-color);
      }
    }
  }
  .more-task {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    color: #65666F;
    font-size: 14px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 12px;
    cursor: pointer;
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.content-right {
  width: 282px;
  margin-left: 24px;
  .promote-shop {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    margin-top: 16px;
    text-align: center;
    &>div {
      cursor: pointer;
    }
    img {
      width: 48px;
      height: 48px;
      margin-bottom: 8px;
    }
  }
  .btn-publish {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    line-height: 46px;
    margin-top: 16px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
  
}

.empty {
  min-height: 280px;
}
// 通知
.notice {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  // align-items: center;
  height: 44px;
  font-size: 16px;
  color: var(--theme-color);
  background-color: #FFF6ED;
  .notice-wrap {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
  }
  .icon-notice {
    width: 30px;
    height: 30px;
    margin-right: 12px;
  }
  .notice-countdown {
    display: flex;
    align-items: center;
    margin-left: 16px;
    &>div {
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 4px;
      background-color: var(--theme-color);
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }
    &>span {
      margin: 0 8px;
    }
  }
}
</style>