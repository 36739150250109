import axios from 'axios'
export function jimDoRequest ({url, body, header}) {
	body = textEncoder(body).buffer
	return new Promise((resolve, reject) => {
		const instance = axios.create()
		const options = {
			url,
			data: body,
			method: 'POST',
			headers: header,
			responseType : 'arraybuffer'
		}
		// console.log('jim 请求', options)
		instance(options).then(res => {
			res.data = new Uint8Array(res.data)
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

function textEncoder(str) {
	let data = unescape(encodeURIComponent(str)).split('').map(val => val.charCodeAt(0));
	return typeof Uint8Array == 'function' ? new Uint8Array(data) : data;
}

export const jimStore =  {
	_setItem: function(key, data) {
		return new Promise((resolve, reject) => {
			localStorage.setItem(key, JSON.stringify(data))
			resolve(true)
		})
	},
	_setItemSync: function(key, data) {
		localStorage.setItem(key, JSON.stringify(data))
	},
	_getItem: function(key) {
		return new Promise((resolve, reject) => {
			const res = localStorage.getItem(key)
			resolve(JSON.parse(res))
		})
	},
	_getItemSync: function(key) {
		// return uni.getStorageSync(key)
		const res = localStorage.getItem(key)
		return JSON.parse(res)
	},
	_removeItem: function(key) {
		return new Promise((resolve, reject) => {
			// uni.removeStorage({
			// 	key,
			// 	success: () => {
			// 		resolve(true)
			// 	}
			// })
			localStorage.removeItem(key)
			resolve(true)
		})
	},
	_removeItemSync: function(key) {
		// uni.removeStorageSync(key)
		localStorage.removeItem(key)
	},
	_clear: function() {
		return new Promise((resolve, reject) => {
			// uni.clearStorage().then(() => {
			// 	resolve(true)
			// }).catch(() => {})
			localStorage.clear()
			resolve(true)
		})
	},
	_clearSync: function() {
		// uni.clearStorageSync()
		localStorage.clear()
	},
}