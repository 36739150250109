<template>
  <div class="panel">
    <PanelTitle title="账户资产" :right-icon="true" @click.native="$router.push('/user/userAsset')"/>
    <div class="asset">
      <div class="flex items-center">
        <img class="icon-money" src="@/assets/images/icon-money.png" />
        <span>剩余任务币</span>
        <img class="icon-detail" src="@/assets/images/icon-detail.png" />
      </div>
      <div class="asset-count">{{userInfo.taskCyBalance || 0}}</div>
    </div>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import { mapState } from 'vuex'
export default {
  name: "Asset",
  components: {
    PanelTitle
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['userInfo'])
  },
};
</script>

<style lang="scss" scoped>
.asset {
  padding-top: 14px;
  font-size: 14px;
  color: #65666f;
  .icon-money {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .icon-detail {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
  .asset-count {
    font-size: 30px;
    font-weight: bold;
    color: #2a2a2a;
    margin-top: 14px;
    text-align: center;
  }
}
</style>