<template>
  <div>
    <slot :hour="hour" :minute="minute" :second="second"></slot>
    <div v-if="!$slots.default && !$scopedSlots.default">{{hour}}:{{minute}}:{{second}}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hour: '00',
      minute: '00',
      second: '00',
      curTime: '',
      timer: null,
    }
  },
  props: {
    time: {
      type: [String, Number],
      default: 0
    }
  },
  watch: {
    time() {
      clearInterval(this.timer)
      this.start()
    }
  },
  created() {
    this.start()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    start() {
      if(this.time <= 0) return
      this.curTime = parseInt(this.time)
      this.timer = setInterval(() => {
        this.curTime = this.curTime - 1000
        const hour = parseInt(this.curTime / 1000 / 60 / 60)
        const minute = parseInt(this.curTime / 1000 / 60 % 60)
        const second = parseInt(this.curTime / 1000 % 60) 
        this.hour = hour.toString().padStart(2, '0')
        this.minute = minute.toString().padStart(2, '0')
        this.second = second.toString().padStart(2, '0')
        if(this.curTime <= 0) {
          this.$emit('finish')
          clearInterval(this.timer)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>