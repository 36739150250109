<template>
  <Popup width="530px" ref="popupRef" :showConfirm="false" :showCancel="false" @close="resetData">
    <template #title>
      <div class="flex between-center">
        <div class="fs16 bold">修改刷新</div>
        <RulePopover title="优惠说明" :rule="detailData?.promotionDescription || ''"/>
      </div>
    </template>
    <div v-if="detailData">
      <div class="tabs">
        <div :class="['tab', curType == 'refresh' ? 'active' : '']" @click="curType='refresh'">购买刷新</div>
        <div :class="['tab', curType == 'modify' ? 'active' : '']" @click="curType='modify'">购买修改</div>
      </div>
      <div class="times">
        <div>
          <div class="num">{{curType == 'refresh' ? detailData.refreshTimes : detailData.changeTimes}}</div>
          <div>总{{curType == 'refresh' ? '刷新' : '修改'}}(次)</div>
        </div>
        <div>
          <div class="num">{{curType == 'refresh' ? detailData.expiringRefreshTimes : detailData.expiringChangeTimes}}</div>
          <div>即将过期{{curType == 'refresh' ? '刷新' : '修改'}}(次)</div>
        </div>
      </div>
      <div class="label">选择数额：</div>
      <div class="pkg-list" v-if="packageList.length > 0">
        <div class="pkg" :class="{'active': !isCustom && pkgIndex === index}" v-for="(item, index) in packageList" :key="index" @click="packageChoose(index)">
          <div>{{item.num}} <span class="unit">次</span> </div>
          <div class="price">￥{{item.price}}</div>
        </div>
        <div :class="['pkg', isCustom ? 'active':'']" @click="handleCustom">
          <div>自定义</div>
        </div>
      </div>
      <el-input v-if="isCustom" placeholder="请输入要购买的数量" type="number" v-model="purchaseValue" >
        <div slot="prepend" class="input-fix">自定义</div>
      </el-input>
      <!-- 支付方式 -->
      <div class="label mt24">支付方式：</div>
      <Payway></Payway>
      <div class="label mt24">购买须知：</div>
      <div class="rule">
        <div>1、刷新与修改次数全局可用</div>
        <div>2、购买的刷新与修改次数永不过期</div>
      </div>
      <!-- 底部按钮 -->
      <div class="bottom-row">
        <div class="total">
          <div>合计 <span class="total-num">{{totalPrice || '0.00'}}</span>元 </div>
          <!-- <div class="tips">点击确认支付，代表你同意 <span class="policy">《自助购买协议》</span> </div> -->
        </div>
        <div class="btn-group">
          <div class="btn-cancel btn-border" @click="close()">取消</div>
          <div class="btn-confirm btn-fill-theme" @click="confirm()">确认支付</div>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import Payway from '@/components/Payway'
import RulePopover from '@/components/RulePopover'
import { buyChangeRefreshApi, changeRefreshApi } from '@/api/user.js'
export default {
  components: {
    Popup,
    Payway,
    RulePopover
  },
  data() {
    return {
      curType: 'refresh', // refresh：购买刷新， modify：购买修改
      detailData: null,
      isCustom: false,
      pkgIndex: '',
      totalPrice: '',
      purchaseValue: ''
    }
  },
  computed: {
    packageList() {
      if(!this.detailData) return []
      return  this.curType == 'refresh' ? this.detailData.refreshPackages || [] : this.detailData.changePackages || []
    }
  },
  watch: {
    purchaseValue() {
      const unitPrice = this.curType == 'refresh' ? this.detailData.refreshPrice : this.detailData.changePrice
      if(this.isCustom) this.totalPrice = unitPrice * Number(this.purchaseValue)
    },
    curType() {
      this.totalPrice = ''
      this.pkgIndex = ''
      this.purchaseValue = ''
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    open() {
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.totalPrice = ''
      this.pkgIndex = ''
      this.curType = 'refresh'
    },
    getDetail() {
      changeRefreshApi({'needDescription': true}).then(res => {
        console.log('刷新修改信息', res)
        this.detailData = res.data
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    packageChoose(index) {
      console.log('index', index, this.packageList)
      this.isCustom = false
      this.pkgIndex = index
      this.totalPrice = this.packageList[index].price
      this.purchaseValue = this.packageList[index].num
    },
    handleCustom() {
      this.isCustom = true
      this.totalPrice = ''
      this.purchaseValue = ''
    },
    confirm() {
      if(!this.purchaseValue) return this.$tips({message: '请输入购买次数', type: 'warning'})
      const formData = {
        'totalAmount': this.totalPrice.toString()
      }
      if(this.curType == 'refresh') {
        Object.assign(formData, {
          'refreshPrice': this.detailData.refreshPrice,
          'refreshTimes': Number(this.purchaseValue),
          'changePrice': this.detailData.changePrice,
          'changeTimes': 0
        })
      } else {
        Object.assign(formData, {
          'refreshPrice': this.detailData.refreshPrice,
          'refreshTimes': 0,
          'changePrice': this.detailData.changePrice,
          'changeTimes': Number(this.purchaseValue)
        })
      }
      console.log('formData', formData)
      buyChangeRefreshApi(formData).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.getDetail()
        this.$store.dispatch('GET_USER_INFO') // 更新余额
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input-group__prepend {
  background-color: #fff;
}
::v-deep .el-input-group__append {
  background-color: #fff;
}
.tabs {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
  .tab {
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: #65666F;
    cursor: pointer;
  }
  .active {
    position: relative;
    color: var(--theme-color);
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 2px;
      background-color: var(--theme-color);
    }
  }
}
// 修改次数
.times {
  display: flex;
  font-size: 14px;
  margin-bottom: 24px;
  &>div {
    flex: 1;
    text-align: center;
  }
  .num {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
  }
}
.pkg-list {
  display: flex;
  flex-wrap: wrap;
  .pkg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 114px;
    height: 72px;
    border-radius: 8px;
    border: 1px solid #EEEDF2;
    font-size: 18px;
    font-weight: bold;
    margin: 0 8px 8px 0;
    cursor: pointer;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .unit {
      font-size: 14px;
      font-weight: normal;
    }
    .price {
      font-size: 14px;
      font-weight: normal;
      color: #B0B3BF;
      margin-top: 8px;
    }
  }
  .active {
    border: 1px solid var(--theme-color);
    background-color: #FFF2ED;
  }
}
.input-fix {
  color: #2A2A2A;
  font-size: 14px;
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 16px;
}
.rule {
  color: #B0B3BF;
  font-size: 14px;
  line-height: 24px;
  white-space: pre-line;
}
.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-cancel, .btn-confirm {
      padding: 10px 24px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: var(--theme-color-red);
    .total-num {
      font-size: 24px;
      font-weight: bold;
    }
    .tips {
      font-size: 12px;
      color: #B0B3BF;
      margin-top: 8px;
    }
    .policy {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}
</style>