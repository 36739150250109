import JimClient from 'jim-client'
export default {
	namespaced: true,
	state: () => ({
		unread: 0
	}),
	mutations: {
		SET_UNREAD(state, num) {
			if(!num) {
				state.unread = JimClient.getUnreadCount([0, 1, 2, 3], [0]).unread
			} else {
				state.unread = num
			}
		}
	}
}