import { loginApi } from '@/api/user.js'
import store from '@/store/index.js'
import request from '@/utils/request.js'
import { getDeviceId } from '@/utils/util.js'
import { $confirm, $tips } from '@/plugins/element/element.js'
import { openShopApi } from '@/api/shop.js'
import router from '@/router'

// 登录续期
export function continueLogin(option) {
	const uid = localStorage.getItem('USER_ID')
	return new Promise((resolve, reject) => {
		if (uid)  {
			const form = {
				loginType: 'autoLogin',
				userId: uid,
				did: getDeviceId(),
			}
			loginApi(form).then(res => {
				store.commit('LOGIN', res.data)
				// 刷新token后重新请求
				request(option).then(newRes => {
					resolve(newRes)
				}).catch(err => {
					reject(err)
				})
			}).catch(err => {
				// 续期失败
				store.commit('LOGOUT')
			})
		} else {
			// 续期失败
			store.commit('LOGOUT')
		}
	})
}

// 开通店铺
export function openShop() {
	// 未开通店铺
	$confirm(`您尚未开通店铺，是否开通？开通即同意
	<a class="policy" target="_blank" href="http://global.xuniuwang.com/pro_net_rules.html">《网络信息推广服务协议》</a>
	<a class="policy" href="http://global.xuniuwang.com/pro_task_rules.html">《任务推广服务协议》</a>`, '提示', {
		dangerouslyUseHTMLString: true,
		closeOnPressEscape: false,
		closeOnClickModal: false,
		confirmButtonText: '立即开通',
		cancelButtonText: '退出系统'
	}).then(action => {
		console.log('action', action)
		if(action == 'confirm') {
			openShopApi().then(openRes => {
				console.log('开通店铺', openRes)
				router.go(0) // 刷新当前页面
			}).catch(err => {
				$tips({message: err, type: 'error'})
			})
		}
	}).catch(err => {
		store.commit('LOGOUT')
	})
}

// 
export function realName(data) {
	$confirm(data.msg, '提示', {
		closeOnPressEscape: false,
		closeOnClickModal: false,
		confirmButtonText: '去实名',
		cancelButtonText: '退出系统'
	}).then(action => {
		if(action == 'confirm') {
			store.commit('SET_REALNAME', false)
			router.push('/user/userAuth')
		}
	}).catch(err => {
		store.commit('LOGOUT')
	})
}