import request from '@/utils/request'

/**
 * 登录 & 登录续期 & 发送验证码
 */
export function loginApi(data = {}) {
	return request({
    url: 'user/login',
    method: 'POST',
    data
  })
}

/**
 * 退出登录
 */
export function logoutApi(data = {}) {
	return request({
    url: 'user/logout',
    method: 'POST',
    data
  })
}

/**
 * 获取用户数据
 */
export function userInfoApi(data = {}) {
	return request({
    url: 'user/mine',
    method: 'POST',
    data
  })
}

/**
 * 获取刷新/修改数据
 */
export function changeRefreshApi(data = {}) {
	return request({
    url: 'user/queryChangeRefresh',
    method: 'POST',
    data
  })
}

/**
 * 购买刷新/修改次数
 */
export function buyChangeRefreshApi(data = {}) {
	return request({
    url: 'user/buyChangeRefresh',
    method: 'POST',
    data
  })
}

/**
 * 用户个人信息管理
 */
export function userProfileApi(data = {}) {
	return request({
    url: 'user/userProfile',
    method: 'POST',
    data
  })
}

/**
 * 换绑安全手机号
 */
export function changePhoneApi(data = {}) {
	return request({
    url: 'user/changePhone',
    method: 'POST',
    data
  })
}

/**
 * 查询/修改登录密码
 */
export function changePwdApi(data = {}) {
	return request({
    url: 'user/changePwd',
    method: 'POST',
    data
  })
}

/**
 * 订单列表
 */
export function billsApi(data = {}) {
	return request({
    url: 'user/bills',
    method: 'POST',
    data
  })
}

/**
 * 订单详情
 */
export function orderDetailApi(data = {}) {
	return request({
    url: 'user/orderDetail',
    method: 'POST',
    data
  })
}

/**
 * 设置密码
 */
export function setPwdApi(data = {}) {
	return request({
    url: 'user/setPwd',
    method: 'POST',
    data
  })
}

/**
 * 提现设置 / 实名
 */
export function cashoutSettingApi(data = {}) {
	return request({
    url: 'user/cashoutSetting',
    method: 'POST',
    data
  })
}
