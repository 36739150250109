<template>
  <div class="panel-title">
    <span :class="titleClass">{{title}}</span>
    <img v-if="rightIcon" src="@/assets/images/arrow-right.png">
  </div>
</template>

<script>
export default {
  name: 'PanelTitle',
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    rightIcon: {
      type: Boolean,
      default: false
    },
    titleClass: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style lang="scss" scoped>
.panel-title {
  display: flex;
  align-content: center;
  justify-content: space-between;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #2A2A2A;
  padding-left: 10px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 2px;
    height: 12px;
    background: var(--theme-color);
  }
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}
</style>