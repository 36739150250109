<template>
  <div class="pay-way">
    <div v-if="showBalance" :class="['pay', payType == 'BALANCE'?'active':'']" @click="changeWay('BALANCE')">
      <img class="icon-pay" src="@/assets/images/icon-money.png">
      <div>
        <div class="payname">任务币支付</div>
        <div class="banlance">余额：{{userInfo.taskCyBalance}}</div>
      </div>
    </div>
    <div :class="['pay', payType == 'ALIPAY'?'active':'']" @click="changeWay('ALIPAY')">
      <img class="icon-pay" src="@/assets/images/icon-alipay.png">
      <div class="payname">支付宝支付</div>
    </div>
    <div :class="['pay', payType == 'WX'?'active':'']" @click="changeWay('WX')">
      <img class="icon-pay" src="@/assets/images/icon-wx.png">
      <div class="payname">微信支付</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      payType: 'BALANCE' // 'ALIPAY' 'WX'
    }
  },
  props: {
    showBalance: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    changeWay(type) {
      if(type != 'BALANCE') {
        this.$confirm('当前支付方式不可用，请联系客服处理', '温馨提示', {
          confirmButtonText: '去联系'
        }).then(action => {
          if(action == 'confirm') {
            this.$router.push({ name: 'Message', params: {isToCustomer: true}})
          }
        }).catch(err => {})
      } else {
        this.payType = type
        this.$emit('change', type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pay-way {
  display: flex;
  align-items: center;
  .pay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 66px;
    width: 156px;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid #E8E8E8;
    cursor: pointer;
    .icon-pay {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .payname {
      font-size: 14px;
      font-weight: bold;
    }
    .banlance {
      color: #65666F;
      font-size: 12px;
      margin-top: 4px;
    }
  }
  .active {
    border: 1px solid var(--theme-color);
    background-color: #FFF2ED;
  }
}
</style>