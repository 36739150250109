<template>
  <el-popover
    placement="left"
    width="400"
    v-bind="$attrs">
    <div slot="reference" class="rule">
      <img src="@/assets/images/icon-detail.png">
      <span>{{title}}</span>
    </div>
    <div style="white-space: pre-line;">{{rule}}</div>
  </el-popover>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '规则说明'
    },
    rule: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.rule {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>