<template>
  <Popup ref="popupRef" title="导出数据" width="460px" confirm-text="导出" @close="resetData" @confirm="confirm">
    <div class="label">选择时间范围：</div>
    <el-date-picker
      v-model="form.date"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd HH:mm:ss">
    </el-date-picker>
    <div class="label mt24">选择任务类型：</div>
    <el-checkbox-group v-model="form.auditStatus">
      <el-checkbox :label="1">待审核</el-checkbox>
      <el-checkbox :label="2">已完成</el-checkbox>
    </el-checkbox-group>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { exportV2Api } from '@/api/task.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      form: {
        date: [],
        auditStatus: []
      }
    }
  },
  methods: {
    open(info) {
      this.taskId = info.taskId || ''
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.form.date = []
      this.form.auditStatus = []
    },
    confirm() {
      if(this.form.date.length == 0) return this.$tips({ type: 'warning', message: '请选择时间范围' })
      if(this.form.auditStatus.length == 0) return this.$tips({ type: 'warning', message: '请选择任务类型' })
      console.log(this.form.date[0])
      const formData = {
        'auditStatus': this.form.auditStatus,
        'endDate': this.form.date[1],
        'startDate': this.form.date[0],
        'taskId': this.taskId,
        // 'timestamp': true,
        'type': 0
      }
      console.log('formData', formData, this.form.date)
      exportV2Api(formData).then(res => {
        console.log('导出数据', res)
        // window.open('https://www.baidu.com')
        window.open(res.data.contentUrl)
      }).catch(err => {
        this.$tips({ type: 'error', message: err })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
}
</style>