import request from '@/utils/request'

/**
  * 获取oss配置
  */
export function shopManageApi(data = {}) {
  return request({
    url: 'shop/manage',
    method: 'POST',
    data
  })
}

/**
  * 获取oss配置
  */
export function openShopApi(data = {}) {
  return request({
    url: 'shop/open',
    method: 'POST',
    data
  })
}