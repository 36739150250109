<template>
  <Popup ref="popupRef" width="370px" :title="`店铺${act == 1 ? '置顶':'推荐'}`" @close="resetData" @confirm="confirm">
    <div class="label red-text">单价：{{unitPrice}}元/小时</div>
    <el-input v-model="inputValue" type="number" placeholder="请输入时长"></el-input>
    <div class="label mt24">价格合计：</div>
    <el-input :value="unitPrice * Number(inputValue)" disabled></el-input>
    <div class="label mt24">支付方式：</div>
    <TaskCoinPay/>
    <div class="rule">
      <div>1.推荐或置顶成功后未到期前可以续期，时间自动累加；</div>
      <div>2.推荐或置顶只针对本店铺有效；</div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import TaskCoinPay from '@/components/TaskCoinPay'
export default {
  components: {
    Popup,
    TaskCoinPay
  },
  data() {
    return {
      act: 1, // 1置顶 2推荐
      inputValue: ''
    }
  },
  computed: {
    unitPrice() {
      const memberBenefit = this.$store.state.memberBenefit
      if(!memberBenefit) return ''
      if(this.act == 1) return memberBenefit.shopTopPrice
      if(this.act == 2) return memberBenefit.shopRecPrice
    }
  },
  methods: {
    open(info) {
      this.act = info.act
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.inputValue = ''
    },
    confirm() {
      if(!this.inputValue) return this.$tips({message: '请输入时长', type: 'warning'})
      const userId = localStorage.getItem('USER_ID')
      const formData = {
        act: this.act,
        price: this.unitPrice,
        shopId: userId,
        time: Number(this.inputValue)
      }
      this.$emit('confirm', formData)
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 8px;
}
.rule {
  font-size: 14px;
  color: #99A3A3;
  margin-top: 24px;
}
</style>