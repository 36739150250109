import request from '@/utils/request'

/**
 * 商家管理后台
 */
export function merchantManageApi(data = {}) {
	return request({
    url: 'merchant/merchantManage',
    method: 'POST',
    data
  })
}

/**
 * 任务列表
 */
export function merchantPubListApi(data = {}) {
	return request({
    url: 'merchant/merchantPubList',
    method: 'POST',
    data
  })
}

/**
 * 获取调整任务数据
 */
export function queryAdjustApi(data = {}) {
	return request({
    url: 'merchant/queryAdjust',
    method: 'POST',
    data
  })
}

/**
 * 查询竞拍详情
 */
export function auctionDetailInfoApi(data = {}) {
	return request({
    url: 'merchant/auctionDetailInfo',
    method: 'POST',
    data
  })
}

/**
 * 竞拍
 */
export function doAuctionApi(data = {}) {
	return request({
    url: 'merchant/doAuction',
    method: 'POST',
    data
  })
}

/**
 * 购买搜索
 */
export function buySearchSeatApi(data = {}) {
	return request({
    url: 'merchant/buySearchSeat',
    method: 'POST',
    data
  })
}

/**
 * 搜索推荐购买页详情信息
 */
export function searchSeatsInfoApi(data = {}) {
	return request({
    url: 'merchant/searchSeatsInfo',
    method: 'POST',
    data
  })
}

/**
 * 发布任务界面获取相关信息
 */
export function queryPublishApi(data = {}) {
	return request({
    url: 'merchant/queryPublish',
    method: 'POST',
    data
  })
}