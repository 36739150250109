import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element/element.js'



import JimClient from 'jim-client'
import { jimDoRequest, jimStore } from '@/utils/jim/jim.js'
import MessageConfig from '@/wfc/client/messageConfig'
const platform = 0
JimClient.init({
	host: 'apidev.xuniuwang.com', //apidev.xuniuwang.com  im.zhihuixiaoqun.com 192.168.0.122
	port: '443', //443  8079
	net: { doRequest: jimDoRequest },
	platform, // wx:1 ali: 2 h5: 0
	ssl: true, // https域名需要开启
	store: jimStore // 本地缓存实现
})
JimClient.registerMessageContents(MessageConfig.MessageContents)
JimClient.eventEmitter = { on: JimClient.on, off: JimClient.off }
JimClient.getMessages = JimClient.loadMessages
Vue.prototype.$im = JimClient
Vue.prototype.$eventBus = new Vue()

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
