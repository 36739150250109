import axios from 'axios'
import store from '@/store'
import md5 from 'js-md5'
import sha256 from 'crypto-js/sha256'
import { getDeviceId, getEncryptKey, deleteEmptyProperty, objToKeyValue, extractBytes, byteToString } from '@/utils/util.js'
import { enc, dec } from '@/utils/sec.js'
import { continueLogin, openShop, realName } from '@/utils/auth.js'
import Setting from '@/setting.js'
import zqb from '@/assets/images/zqb.png'


const baseURL = Setting.HTTP_REQUEST_URL
const service = axios.create({ baseURL, timeout: 60000 })

const headerOpt = {
	'productId': Setting.PRODUCT_ID,
	'token': '',
	'channel': Setting.CHANNEL,
	'versionName': Setting.VERSION_NAME,
	'sVer': '2',
  'did': getDeviceId(),
}
// 加密key
let dataKey = '' //加密数据key
let signKey = '' //签名key

async function baseRequest(options) {
  if(!store.state.dataKey || !store.state.signKey) await initEncryptKeyAsync()
	if(store.state.token) headerOpt.token = store.state.token
	const encData = encryptData(options.data || {}, Object.assign({}, headerOpt, options.headers || {}))
  return new Promise((resolve, reject) => {
    service({
      url: options.url,
      method: options.method || 'POST',
			headers: {
				...encData.headers,
				'Content-Type': 'application/json; charset=utf-8'
			},
			data: encData.data || '',
			responseType: 'arraybuffer',
    }).then(res => {
      // console.log('响应结果', res)
      if(res.status == 200) {
        let str = dec(new Uint8Array(res.data), dataKey)
        let data = JSON.parse(str)
        // console.log('解密结果', data)
        if(data.code == 200) {
          resolve(data)
        }
        else if(data.code == 203) {
          // 登录续期
					const uid = localStorage.getItem('USER_ID')
					if(!uid) return reject(data.msg || '登录已过期')
          continueLogin(options).then(newRes => {
            resolve(newRes)
          }).catch(err => {
            reject(err)
          })
        }
        else if(data.code == 209 || data.code == 2001) {
          store.commit('LOGOUT')
        }
				else if(data.code == 3049) {
					console.log('3049', res)
					openShop(data)
				}
				else if(data.code == 3026) {
					console.log('3026', data)
					realName(data)
				}
        else {
          reject(data.msg || '请求失败')
        }
      } else {
        reject('请求失败')
      }
    }).catch(() => reject(new Error('请求失败')))
  })
}


function initEncryptKeyAsync() {
	return new Promise((resolve, reject) => {
		if(store.state.dataKey && store.state.signKey) return resolve()
		// 正式环境密钥
		// https://union.xuniu.vip/duck.png
		axios({
			url: zqb,
			method: 'GET',
			responseType: 'arraybuffer',
		}).then(res => {
			console.log('------zqb------', res)
			let bytes = extractBytes(res.data);
			let source = byteToString(bytes)
			const signKey = getEncryptKey(source, 8)
			const dataKey = getEncryptKey(source, 16)
			store.commit('SET_KEY', {signKey, dataKey})
			resolve()
		}).catch(err => {})

		// 测试环境密钥
		// const timer = setTimeout(() => {
		// 	const source = 'aSFIndnzGcBKqOF9AJsieDq8Ud4Fc3sxJu1hzu7wzf3fzVusQ3YJrfztpi6Nrv9XyrsIPG5ihYR0vAAi1mgkUCmOxG9mLU4ez9DIvxK6rHnpkcOrRFLuxQUg7pk8pTzds83iQUL7GCGm7eW5WFiKnjM7a1zebEAUndYkibIutkGQcByzxNDoQLoiWopfoijFpxQxo92j9W9SFP62QUZ7O0bc69htr5poaBHK41UvqDC0kD8K1OrJjfoM6rbUTGwrv8z0UybeNXccf8LoI3CvO4KBhi2sgXhS72b2FFROzLkXJeTlhNR8ahr7QEUXk7Ly3NeGU3THKGkZJSpOIztgCLdtr2oaBhGPcBvZ4FACAKVK4ExqbcBzvo7wJmAV4eTqtMpUwWZWMOqCCsq7l1E8WPB8R0K9z2A2MaK2fzLWAZeNqdevrGCzAEY9DeNpWutNMW5hYRkCYGtqzu0kFG2tu6h2mSgYfJr86JxloWQ0TtGs7zVFvL46OIFqNcpTOE1FLB24rVcfwAarQqmUTnY3qc2vcjdQxnoKktH689C2js7yUV4zkP4x1DbBeecjUNGSkzLKOByrvJM9g43oSsBFnJVhXHX1EMcsZFRvoV0l9brM4pGY4StuIqpHF2RN0Nn5DCxqRevQWVmIbH4tnfy1nEN5TfDZADwVIAEsZ1xjk890ovSMoYXjfNLZfhwxxYa6FXPTFnXNOTMqvkpS3KVb8istBHfYMY9adScOi2UovVrPzF7fu6Wld6l5iQNTWsFsdZEHCxkJv6OP7R6yGEvdU39FmvOr1zBy'
		// 	// const source = 'wBe20QMheTuM1rMFr2GliBmWGXIGNbnK2Lu3hkSOrnOo9zCjrvYguAZ4bZ816eLyN6tI9TDVv8fepnB0IvQpJVSLsyZr7IoI6zvoZ0wScWtGt84g5EmtZV6oj726ZW2eZYmE7atfNzcOFKnsyDxCpAZmrVndShfKAhpxb2smIulcxSYwmFf9yDdKeC7jgm3NGRLdBSpyfalrzt2cBL1ISF2NpFIn3KmywOsITHDSLvr7lFNVkJcKQLL0ISciBrxTij2VNaNPNE5dfajY2BddUNDwE6ugb6NYxHQsgiwUJL2efPQR7grOqSUia8uGj1CuWwEowxa6XSJ5xzGutjdJvEAByC41nWTRdZCf0S47ZezSqpBZhhrf0Q4rF4XuVYwIKLpe1oh3SWGRXfGYtUXqN6qDvWsHnvwWyUyAaGcP1HOrxe2GwGo1oeJjifwbK3AAms96SwmHnZ84vEB2DWMjyuL6jPtQmOjN1cPLkETXA60sFvy5zTz4zHuYDbmyd1qypquHJQ9rUWFKtHaHZdlgBnefzjwOAL3sFCqidw7gF3VLvIQhqLTC7PjaFq0xWqMJjhFhVOryHb6lsGvxNNhR75Bk9wdsmagdhDzoEyYoVDz32a2mtIoW4x7Qe2jybhEkHdymGqU5vubvX6tRgDsdXAjDsC8eZKmPcKneGF2dGfpFWXiP9b6hSKPhbfyGFPOpvjK2p8SVaNx52a7E1DQ8PunYnCi6qw4MPJQ5u3u1XQktnmKYjQ8sk6KNZemh7ggN3UVgd7O2urrkIWZl4k4UxPYSwB2iQmoN'
		// 	const signKey = getEncryptKey(source, 8)
		// 	const dataKey = getEncryptKey(source, 16)
		// 	store.commit('SET_KEY', {signKey, dataKey})
		// 	resolve()
		// 	clearTimeout(timer)
		// }, 100)
		
	})
}

function encryptData(reqData, headers) {
	const result = {}
	dataKey = store.state.dataKey //加密数据key
	signKey = store.state.signKey //签名key
	let jsonStr = JSON.stringify(reqData)
	// data加密
	let encRes = enc(jsonStr, dataKey) //对数据加密
	result.data = encRes.buffer
	
	// header sign
	let value = md5(encRes);
	let obj = {
		data: value,
		...headers,
	}
	deleteEmptyProperty(obj)
	let shaArr = sha256(objToKeyValue(obj) + signKey)
	headers['sign'] = shaArr.toString()
	result.headers = headers
	
	return result
}


export default baseRequest

