<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import DevicePixelRatio from '@/libs/DevicePixelRatio.js';
export default {
  data() {
    return {}
  },
  created() {
    // new DevicePixelRatio().init()
  }
}
</script>

<style lang="scss">
  @import './styles/normalize.css';
  @import './styles/base.scss';
</style>
