import md5 from "js-md5"
import JSEncrypt from 'jsencrypt'
import { PWD_KEY } from '@/setting.js'
export function getDeviceId() {
  let did = localStorage.getItem('DEVICE_ID') || ''
  if(!did) {
    let str ="23014502zx8cvbn7masdfg5hjklq6w9ertyu0iop4167893"
    for (let i = 0; i < 16; i++) {
      did += str[Math.floor(Math.random() * str.length)]
    }
		did = md5('task' + did)
    localStorage.setItem('DEVICE_ID', did)
  }
	return did

  // return 'task17018433786346808808'
}

// 获取数据加密key和签名key
export function getEncryptKey(keySource, len) {
	let did = getDeviceId()
	if (!did) return ''
	let hexDid = convertToHex(did);
	let hexDidSize = parseInt(hexDid.length / len);
	let ksSize = parseInt(keySource.length / len);
	let str = "";
	for (let i = 0; i < len; i++) {
		let section = hexDid.substring(i * hexDidSize, (i + 1) * hexDidSize);
		let index = parseInt(section, 16) % ksSize;
		str += keySource.charAt(ksSize * i + index);
	}
	return str
}

//提取字典
export function extractBytes(buffer) {
	let byteArray = new Uint8Array(buffer);
	let len = 0
	let	bit = 0
	let	bitVal
	let i
	for (i = 54; i < 86; i++) {
		bitVal = byteArray[i];
		len += (bitVal & 0x01) << (bit++);
	}
	bit = 0;
	let ch = 0
	let bytes = []
	for (i = 86; i < byteArray.length;) {
		if (bit < 8) {
			let byte = byteArray[i++];
			bitVal = (byte & 0x01) << bit;
			if ((bitVal >> 7) === 1 && bit === 7) {
				ch -= bitVal;
			} else {
				ch += bitVal;
			}
			bit++;
			continue;
		}
		bytes.push(ch);
		ch = 0;
		bit = 0;
		if (bytes.length >= len) {
			break;
		}
	}
	return bytes;
}

//byte数组转换string
export function byteToString(arr) {
	if (typeof arr === 'string') return arr
	let str = ''
	let	_arr = arr
	for (let i = 0; i < _arr.length; i++) {
		let one = _arr[i].toString(2)
		let	v = one.match(/^1+?(?=0)/);
		if (v && one.length == 8) {
			let bytesLength = v[0].length;
			let store = _arr[i].toString(2).slice(7 - bytesLength);
			for (let st = 1; st < bytesLength; st++) {
				store += _arr[st + i].toString(2).slice(2);
			}
			str += String.fromCharCode(parseInt(store, 2));
			i += bytesLength - 1;
		} else {
			str += String.fromCharCode(_arr[i]);
		}
	}
	return str;
}

/**
 * @param string 
 * @returns 16进制字符串
 */
export function convertToHex(str) {
	let res = ""
	for (let i = 0; i < str.length; i++) {
		res += str.charCodeAt(i).toString(16);
	}
	return res;
}

/**
 * @param buffer 
 * @returns 16进制字符串
 */
export function bufferToHex(buffer) {
	// buffer is an ArrayBuffer
	return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

/**
 * @param hexString 
 * @returns ArrayBuffer
 */
export function hexToArrayBuffer(hexString) {
	let typedArray = new Uint8Array(hexString.match(/[\da-f]{2}/gi).map(h  => parseInt(h, 16)))
	return typedArray.buffer
}

// 删除空属性
export function deleteEmptyProperty(obj){
  for(let key in obj){
    if (obj[key]==='') {
      delete obj[key]
    }
  }
	return obj
}

export function objToKeyValue(obj) {
	let str = ''
	const keys = Object.keys(obj).sort()
	for (let key of keys ) {
		let value = obj[key]
		str = str === '' ? `${key}=${value}` : str + '&' + `${key}=${value}`
	}
	return str
}

// 密码加密函数
export function pwdEncrypt(pwd) {
	const enctor = new JSEncrypt()
	enctor.setPublicKey(PWD_KEY)
	return enctor.encrypt(pwd)
}

// web复制
export function copyUtil(val) {
	return navigator.clipboard.writeText(val) // 返回Promise
}

/**
 * 日期格式化
*/
export function dateFormatUtil(data, sp = '-', isShowTime = false) {
	const date = new Date(data)
	const year = date.getFullYear().toString()
	const month = (date.getMonth() + 1).toString().padStart(2, '0')
	const day = date.getDate().toString().padStart(2, '0')
	const hour = date.getHours().toString().padStart(2, '0')
	const minute = date.getMinutes().toString().padStart(2, '0')
	const second = date.getSeconds().toString().padStart(2, '0')
	
	if(isShowTime) return `${[year, month, day].join(sp)} ${hour}:${minute}:${second}`
	return `${[year, month, day].join(sp)}`
}

// 返回聊天格式日期
export function formatMsgDate(time) {
	const curDate = new Date()
	const msgDate = new Date(time)
	const hour = msgDate.getHours().toString().padStart(2, '0')
	const minute = msgDate.getMinutes().toString().padStart(2, '0')
	const curDateTime = new Date(dateFormatUtil(curDate.getTime())).getTime()
	const msgDateTime = new Date(dateFormatUtil(time)).getTime()
	if(curDateTime - msgDateTime < 24*3600*1000 && curDate.getDate() == msgDate.getDate()) return `${hour}:${minute}`
	if(curDateTime - msgDateTime < 48*3600*1000 && curDate.getDate() != msgDate.getDate()) return `昨天 ${hour}:${minute}`
	if(curDateTime - msgDateTime > 6*24*3600*1000) return dateFormatUtil(time, '/', true)
	const weeksMap = {
		0: '星期日',
		1: '星期一',
		2: '星期二',
		3: '星期三',
		4: '星期四',
		5: '星期五',
		6: '星期六'
	}
	return `${weeksMap[msgDate.getDay()]} ${hour}:${minute}`
}